import { useRouter } from 'next/router'
import React from 'react'

import { useAuth } from 'utils/hooks/useAuth'

import LandingPage from 'app/LandingPage'

export const Index = () => {
  const router = useRouter()
  const { bcksUserSession } = useAuth()

  if (bcksUserSession) {
    if (router.query && router.query.from) {
      router.replace(router.query.from as string)
    } else {
      router.replace('/home')
    }

    return null
  }

  return <LandingPage />
}

export default Index

Index.Layout = ({ children }) => children
