import clsx from 'clsx'
import type { ReactNode } from 'react'

import type { CollapsibleProps } from '@wartek-id/collapsible'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleIcon,
  CollapsibleTrigger,
} from '@wartek-id/collapsible'
import { Text } from '@wartek-id/text'

export type PanelCollapsibleProps = CollapsibleProps & {
  title: string
  children?: ReactNode
}

export const PanelCollapsible = ({
  children,
  className,
  title,
  ...collapsibleProps
}: PanelCollapsibleProps) => {
  return (
    <Collapsible
      className={clsx(className, 'rounded-lg border border-subdued')}
      {...collapsibleProps}
    >
      <CollapsibleTrigger className="!border-none !px-6 !py-5">
        <Text as="span" className="text-left" variant="heading-md">
          {title}
        </Text>
        <CollapsibleIcon />
      </CollapsibleTrigger>
      <CollapsibleContent className="border-t border-t-disabled px-6 pb-7 pt-5">
        {children}
      </CollapsibleContent>
    </Collapsible>
  )
}

export default Collapsible
